import React from 'react'
import style from './styles.css'
import KongLayout from './components/Layout'
import AugmentingResponses from './components/AugmentingResponses'
import AugmentingParameters from './components/AugmentingParameters'
// Overwriting requires lowercase versions of the react components in swagger-ui
const SwaggerUIKongTheme = (system) => {
  return {
    components: {
      curl: () => null,
      KongLayout: KongLayout
    },
    wrapComponents: {
      responses: (Original, system) => (props) => {
        return (
          <div className="right-side-wrapper">
            <AugmentingResponses {...props} system={system} />
            <Original { ...props} />
          </div>
        )
      },
      parameters: (Original, system) => (props) => {
        return (
          <div>
            <AugmentingParameters {...props} system={system} />
            <Original { ...props} />
          </div>
        )
      }
    }
  }
}

export { SwaggerUIKongTheme, style, KongLayout }
