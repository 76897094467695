// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from 'react'

// mobx
import ReactMarkdown from 'react-markdown'
import 'github-markdown-css/github-markdown.css'
import MarkNav from 'markdown-navbar';
import 'markdown-navbar/dist/navbar.css';
import remarkGfm from 'remark-gfm'

// semantic-ui

import md from '../assets/md/Open API Integration.md'

const CustomerServer = () => {
  const [markdown, setMd] = useState('')
  useEffect(() => {
    fetch(md).then(res => res.text()).then(text => setMd(text))
  }, [])

  function handleScroll(e, item, hash) {
    var element = document.querySelector(`[data-id=${hash}]`);
    element.scrollIntoView()
  }

  return (
    <>
      <div className='row customer-server'>
        <div className='markdown-nav-bar'>
          <MarkNav
            source={markdown}
            onNavItemClick={(e, item, hash) => handleScroll(e, item, hash)}
          />
        </div>
        <div className='customer-server-markdown'>
          <ReactMarkdown
            className='markdown-body'
            children={markdown}
            remarkPlugins={[remarkGfm]}
          />
        </div>
      </div>
    </>
  )
}
export default CustomerServer
