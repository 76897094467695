import React from 'react'

export default class AugmentingResponses extends React.Component {
    shouldComponentUpdate (nextProps) {
        // BUG: props.tryItOutResponse is always coming back as a new Immutable instance
        const render = this.props.tryItOutResponse !== nextProps.tryItOutResponse ||
        this.props.responses !== nextProps.responses ||
        this.props.produces !== nextProps.produces ||
        this.props.producesValue !== nextProps.producesValue ||
        this.props.displayRequestDuration !== nextProps.displayRequestDuration ||
        this.props.path !== nextProps.path ||
        this.props.method !== nextProps.method
        return render
    }

    render () {
        const {
            specSelectors
        } = this.props
        const params = specSelectors.getParameter()

        return (
            <div>{params}</div>
        )
    }
}
