import React from 'react'

// Create the layout component
export default class KongLayout extends React.Component {
  render () {
    const {
      errSelectors,
      specSelectors,
      getComponent,
      getConfigs
    } = this.props

    const SvgAssets = getComponent('SvgAssets')
    const InfoContainer = getComponent('InfoContainer', true)
    const VersionPragmaFilter = getComponent('VersionPragmaFilter')
    const Operations = getComponent('operations', true)
    const Models = getComponent('Models', true)
    const Col = getComponent('Col')
    const Errors = getComponent('errors', true)

    const ServersContainer = getComponent('ServersContainer', true)
    const SchemesContainer = getComponent('SchemesContainer', true)
    const AuthorizeBtnContainer = getComponent('AuthorizeBtnContainer', true)
    const Sidebar = getComponent('Sidebar', true)

    const isSwagger2 = specSelectors.isSwagger2()
    const isOAS3 = specSelectors.isOAS3()

    const isSpecEmpty = !specSelectors.specStr()

    const loadingStatus = specSelectors.loadingStatus()

    let loadingMessage = null

    const config = getConfigs()
    const swaggerAbsoluteTop = {
      top: (config.theme && config.theme.swaggerAbsoluteTop) || '0'
    }
    const hasSidebar = config.theme && config.theme.hasSidebar

    if (loadingStatus === 'loading') {
      loadingMessage = <div className='info'>
        <div className='loading-container'>
          <div className='loading'></div>
        </div>
      </div>
    }

    if (loadingStatus === 'failed') {
      loadingMessage = <div className='info'>
        <div className='loading-container'>
          <h4 className='title'>Failed to load API definition.</h4>
          <Errors />
        </div>
      </div>
    }

    if (loadingStatus === 'failedConfig') {
      const lastErr = errSelectors.lastError()
      const lastErrMsg = lastErr ? lastErr.get('message') : ''
      loadingMessage = <div className='info' style={{ maxWidth: '880px', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
        <div className='loading-container'>
          <h4 className='title'>Failed to load remote configuration.</h4>
          <p>{lastErrMsg}</p>
        </div>
      </div>
    }

    if (!loadingMessage && isSpecEmpty) {
      loadingMessage = <h4>No API definition provided.</h4>
    }

    if (loadingMessage) {
      return <div className="swagger-ui">
        <div className="loading-container">
          {loadingMessage}
        </div>
      </div>
    }

    const servers = specSelectors.servers()
    const schemes = specSelectors.schemes()

    const hasServers = servers && servers.size
    const hasSchemes = schemes && schemes.size
    const hasSecurityDefinitions = !!specSelectors.securityDefinitions()

    return (
      <div className='wide' style={swaggerAbsoluteTop}>
        { hasSidebar && <Sidebar getConfigs={getConfigs}/> }
        <div className={'swagger-ui ' + (hasSidebar && 'has-sidebar')}>
          <SvgAssets />
          <VersionPragmaFilter isSwagger2={isSwagger2} isOAS3={isOAS3} alsoShow={<Errors />}>
            {hasServers || hasSchemes || hasSecurityDefinitions ? (
              <div className="scheme-container">
                <Col className="schemes wrapper" mobile={12}>
                  {hasServers ? (<ServersContainer />) : null}
                  {hasSchemes ? (<SchemesContainer />) : null}
                  {hasSecurityDefinitions ? (<AuthorizeBtnContainer />) : null}
                </Col>
              </div>
            ) : null}

            <Errors />
            <div className="main-container">
              <InfoContainer />
              <Operations />
              <Models />
            </div>
          </VersionPragmaFilter>
        </div>
      </div>
    )
  }
}
