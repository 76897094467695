// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Redirect } from 'react-router-dom'

// semantic-ui
import { Loader, Menu } from 'semantic-ui-react'

// store
import { observer } from 'mobx-react'
import { store } from 'services/state'

// utilities
import _ from 'lodash'
import Sidebar from 'components/Sidebar/Sidebar'
import MenuLink from 'components/MenuLink'

function getApisWithStages (selectedApiId, selectedStage, activateFirst) {
  const apiList = [].concat(_.get(store, 'apiList.generic', []), _.get(store, 'apiList.apiGateway', [])).map(api => ({
    group: api.apiId || api.id,
    id: api.apiStage || api.id,
    title: api.swagger.info.title,
    paths: jsonToArr(JSON.parse(JSON.stringify(api.swagger.paths))),
    route: `/apis/${api.id}` + (api.apiStage ? '/' + api.apiStage : ''),
    active: (
      (selectedApiId && (api.id === selectedApiId || api.apiId === selectedApiId)) &&
      (!selectedStage || api.apiStage === selectedStage)
    ),
    stage: api.apiStage
  }))

  return _.toPairs(_.groupBy(apiList, 'group'))
    .map(([group, apis]) => ({ group, apis, active: _.some(apis, 'active'), title: apis[0].title }))
}

function jsonToArr (json) {
  var arr = []
  Object.keys(json).forEach(function (k) {
    arr.push(json[k])
  })
  return arr;
}

function handleScroll(summary) {
  const elements = document.getElementsByClassName('opblock-summary-description')
  for (var element of elements) {
    if (element.innerText === summary) {
      console.log(element)
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

export default observer(function ApisMenu (props) {
  // If we're still loading, display a spinner.
  // If we're not loading, and we don't have any apis, display a message.
  // If we're not loading, and we have some apis, render the appropriate api subsections for apiGateway and generic apis
  if (!store.apiList.loaded) {
    return <Loader active />
  }

  const apiGroupList = getApisWithStages(
    props.activateFirst && props.path.params.apiId,
    props.path.params.stage,
    props.activateFirst
  )

  if (!apiGroupList.length) {
    return <p style={{ padding: '13px 16px', color: 'whitesmoke' }}>No APIs Published</p>
  }

  if (props.activateFirst && !props.path.params.apiId) {
    return <Redirect to={apiGroupList[0].apis[0].route} />
  }

  return (
    <Sidebar>
      <>
        {apiGroupList.map(({ apis, title, group, active }) => (
          <MenuLink className="sidebar-item" key={group} active={active} to={apis[0].route}>
            <div className='api-title'><span>{title}</span></div>
            {apis && apis[0].paths &&
              <Menu.Menu className="second-menu">
                {apis[0].paths.map((path) => (
                  <>
                    {path.get &&
                      <MenuLink key={path.get.summary} style={{ fontWeight: '400' }} onClick={() => handleScroll(path.get.summary)}>
                        <span className='get'>
                          get
                        </span>
                        <span style={{ width: '70%' }}>{path.get.summary}</span>
                      </MenuLink>
                    }
                    {path.post &&
                      <MenuLink key={path.post.summary} style={{ fontWeight: '400' }} onClick={() => handleScroll(path.post.summary)}>
                        <span className='post'>
                          post
                        </span>
                        <span style={{ width: '70%' }}>{path.post.summary}</span>
                      </MenuLink>
                    }
                  </>
                ))}
              </Menu.Menu>
            }
          </MenuLink>
        ))}
      </>
    </Sidebar>
  )
})
