// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Menu, Image } from 'semantic-ui-react'

import {
  isAdmin,
  isAuthenticated,
  logout,
  getLoginRedirectUrl
} from 'services/self'

import { cognitoDomain, cognitoClientId } from '../services/api'

// mobx
import { observer } from 'mobx-react'

// components
import MenuLink from 'components/MenuLink'
import { store } from 'services/state'

function getCognitoUrl (type) {
  const redirectUri = getLoginRedirectUrl()
  return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
}

export const NavBar = observer(
  class NavBar extends React.Component {
    constructor () {
      super()
      this.state = {
        activeTab: ''
      }
    }

    static getDerivedStateFromProps (nextProps, nextState) {
      return ({ activeTab: window.location.href })
    }

    render () {
      const email = store.user && store.user.email
      const { activeTab } = this.state
      return <Menu className="narbar-menu" inverted borderless attached style={{ flex: '0 0 auto' }} stackable>
        <div style={{ display: 'flex' }}>
          <Image size='mini' src='/custom-content/logo.png' style={{ margin: 'auto 0.625rem auto 1.875rem' }} />
        </div>
        <MenuLink active={ activeTab.indexOf('apis') < 0 && activeTab.indexOf('integration') < 0 } to='/'>
          Home
        </MenuLink>

        {/* <MenuLink to='/getting-started'>{fragments.GettingStarted.title}</MenuLink> */}
        <MenuLink active={ activeTab.indexOf('apis') >= 0 } to='/apis'>APIs</MenuLink>
        <MenuLink active={ activeTab.indexOf('integration') >= 0 } to='/integration'>Integration Tutorial</MenuLink>

        <Menu.Menu position='right'>
          {isAuthenticated() ? <>
            {isAdmin() && <MenuLink to='/admin/apis'>Admin Panel</MenuLink>}
            {/* {isRegistered() && <MenuLink to='/dashboard'>My Dashboard</MenuLink>} */}
            <MenuLink onClick={logout}>
              <div className='signOut' style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', paddingTop: '2px' }}>
                <span className='out'>
                  Sign out
                </span>
                {email && <span className='email'>
                  ({email})
                </span>}
              </div>
            </MenuLink>
          </> : <>
            <MenuLink to={getCognitoUrl('login')}>Sign In</MenuLink>
          </>}
        </Menu.Menu>
      </Menu>
    }
  }
)

export default NavBar
